.app11 {
    .gallery-container {
        display: grid;
        gap: 10px;
        /* Space between images */
        flex: 1;
        /* Take up the available space in the flex parent */
        justify-content: center;
    }

    .gallery-item img {
        display: block;
        width: 100%;
        /* Ensure the image fits within the grid item */
        height: 100%;
        /* Ensure the image fits within the grid item */
        object-fit: cover;
        /* Maintain aspect ratio while filling the space */
    }

    .current-media-overlay {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 9999;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .cameraContainer img,
    .cameraContainer video {
        object-fit: cover;
        width: 100%;
        height: 100%;
        border-radius: 15px;
    }

    .cameraContainerPreview img,
    .cameraContainerPreview video {
        object-fit: cover;
        width: 100%;
        max-width: 600px;
    }

    .modal .cameraContainer {
        overflow: hidden;
    }

    .modal .cameraContainer img,
    .modal .cameraContainer video {
        object-fit: cover;
        width: 100vw;
        height: 100vh;
        border-radius: 0px;
    }

    .modal .cameraContainerPreview {
        position: relative;
        overflow-y: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .modal .cameraButtonContainer {
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 5px;
        position: absolute;
        bottom: 50px;
        right: calc(50% - 66px);
    }


    .imageContainer {
        height: 350px;
    }

    .imageContainer img {
        object-fit: contain;
    }

    .photoButton {
        border-radius: 50% !important;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        text-align: center !important;
        flex-direction: column !important;
        position: relative;
        width: 50px;
        height: 50px;
    }

    .framePreview {
        background-image: var(--frame);
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

}