.languageSeparator {
    margin: 1.25rem 0rem;
    width: 100%;
    height: 1px;
    background-color: var(--bs-border-color) !important;
}

.btn-large {
    border-radius: 35px;
    min-width: 175px;
    color: var(--baseColorText) !important;
}

.text-underline {
    text-decoration: underline;
}

/* InitialPage - Tutorial */

.tutorial-card-body {
    color: var(--baseColorText) !important;
    max-width: 550px;
    height: 100%;
    padding: 1rem 3rem;
    display: flex;
    text-align: center;
    flex-direction: column;
    margin: auto;
    align-items: center;
}

.form-card-body {
    color: var(--baseColorText) !important;
    max-width: 720px;
    height: 100%;
    text-align: center;
    padding: 2rem 3rem;
    margin: auto;
}

.form-floating>.form-control,
.form-floating>.form-control-plaintext,
.form-floating>.form-select {
    height: calc(3rem + 2px) !important;
}

.form-floating>.form-control:focus,
.form-floating>.form-control:not(:placeholder-shown),
.form-floating>.form-control-plaintext:focus,
.form-floating>.form-control-plaintext:not(:placeholder-shown) {
    padding-top: 1.825rem !important;
}

.contacts-card-body {
    color: var(--baseColorText) !important;
    height: 100%;
    display: flex;
    text-align: center;
    padding: 3rem;
    flex-direction: column;
    justify-content: space-around;
}

.stripLogo {
    height: 60px;
    width: 100%;
}

.logoCircle {
    border-radius: 50%;
    width: 135px;
    height: 135px;
    position: absolute;
}

.logoCircle img {
    width: 100%;
    height: 100%;
}

.tutorialSlideController {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
}

.tutorialSlideController div {
    border-radius: 50%;
    width: 10px;
    height: 10px;
    background: var(--baseColorText);
    cursor: pointer;
}

.tutorialSkip {
    text-decoration: underline;
}

.tutorialSlideController :not(:last-child) {
    margin-right: 10px;
}

/* InitialPage - Login */
.form-label,
.form-floating>label {
    color: var(--baseColorText);
}

.avatarProfileRegister {
    width: 130px;
    height: 130px;
    border: 8px solid var(--primaryColor);
    border-radius: 50% !important;
    object-fit: cover;
}

.avatarProfile3 {
    width: 70px !important;
    height: 70px !important;
    object-fit: cover;
    border-radius: 50% !important;
    border: 3px solid var(--primaryColorText);
}

.avatarProfile2 {
    width: 95px !important;
    height: 95px !important;
    object-fit: cover;
    border-radius: 50% !important;
    border: 5px solid var(--primaryColorText);
}

.avatarProfile {
    width: 180px !important;
    height: 180px !important;
    object-fit: cover;
    border: 8px solid var(--primaryColor);
    border-radius: 50% !important;
}

.avatarDivProfile {
    width: 140px !important;
    height: 140px !important;
    position: relative;
    margin: auto;
}

.frameX {
    z-index: 1;
    border: 8px solid var(--primaryColor);
    width: 140px;
    height: 140px;
    border-radius: 50%;
    position: absolute;
}

.avatarProfileContato01 {
    width: 140px !important;
    height: 140px !important;
    object-fit: cover;
    border-radius: 50% !important;
}

#showPassword {
    cursor: pointer;
    background-color: transparent !important;
    position: absolute;
    border: 0px;
    top: 0px;
    right: 10px;
    bottom: 0;
}

.loginInput input {
    height: 50px;
    border-top: 0px !important;
    border-right: 0px !important;
    border-left: 0px !important;
    border-radius: 0px;
    border-bottom: 2px solid var(--bs-border-color) !important;
    color: var(--baseColorText) !important;
    background-color: transparent !important;
}

.loginInput svg {
    width: 20px;
    height: 20px;
}

.leaveIconLogin {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    display: flex;
    position: absolute;
    left: 40px;
}

.cameraIcon {
    background-color: var(--primaryColor);
    color: var(--primaryColorText);
    z-index: 2;
    width: 40px;
    height: 40px;
    font-size: 1rem;
    position: absolute;
    bottom: 0px;
    right: 0px;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.cameraIcon svg {
    width: 1.5em;
    height: 1.5em;
}

/* Home */

.home-card-body {
    color: var(--baseColorText) !important;
    max-width: 960px;
    text-align: center;
    padding: 2rem;
    margin: auto;
}

.frameProfile {
    cursor: auto;
    width: 100%;
    padding: 1rem;
}

.profileCard {
    padding: 1rem;
    border-radius: 10px;
}

.contactCard {
    padding: 1rem;
    border-radius: 10px;
    width: 100%;
    font-size: 1rem;
    color: var(--baseColorText);
    border: 1px solid var(--bs-border-color);
}

.avatarProfileContato02 {
    width: 100%;
    height: 100%;
}

.contactCard .frameProfileSeparator {
    border-right: 1px solid var(--bs-border-color);
}

.qrcode-frame #video {
    max-height: 435px;
    border: 8px solid var(--primaryColor);
    border-radius: 10px;
}

.qrcode-frame {
    max-height: 435px;
    border-radius: 10px;
}

.pageLoaderContainer {
    height: calc(100vh - 50px);
    display: flex;
}

.pageLoaderContainer .loaderScreen {
    margin: auto;
}

.pageLoaderContainer .qrcode-feedback {
    margin-top: 2rem;
    border-radius: 10px;
    height: 50px;
    width: 100%;
}

.logoLogin {
    max-height: 250px;
    object-fit: cover;
    height: 100%;
    max-width: 650px;
    width: 100%;
    margin: auto auto 2rem auto;
}

.homeCardsFirst {
    background-color: var(--primaryColor);
    color: var(--primaryColorText);
    cursor: pointer;
    width: 100%;
    border-radius: 20px;
    margin-bottom: 1rem;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;
}

.homeCards {
    background-color: var(--primaryColor);
    color: var(--primaryColorText);
    cursor: pointer;
    height: 172px;
    width: 100%;
    border-radius: 20px;
    margin-bottom: 1rem;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;
    padding-left: 1.5rem;
    padding-right: 1rem;
}

.homeCardsIcon svg {
    width: 65px;
    height: 65px;
}

.homeCardsIconArrow {
    position: absolute;
    right: 15px;
    bottom: 10px;
}

.homeCardsIconArrow svg {
    width: 20px;
    height: 20px;
}

.inputQRcode {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    opacity: 0;
    z-index: 5;
}

.pageTitle {
    height: 45px;
    width: 100%;
    margin: 0rem 1rem 0rem 1rem;
    align-items: center;
    font-size: 1.3rem;
    justify-content: center;
    display: flex;
    border-radius: 30px;
}

.iconSize {
    cursor: pointer;
    flex-shrink: 0;
    min-width: 0;
    min-height: 0;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    display: flex;
}

.backIconSize {
    width: 45px;
    height: 45px;
}

.qrIconHome {
    position: absolute;
    right: 15px;
    top: 15px;
    width: 45px;
    height: 45px;
    border: 1px solid;
    margin-bottom: 2rem;
}

.leaveIconHome {
    position: absolute;
    left: 15px;
    top: 15px;
    width: 45px;
    height: 45px;
    border: 1px solid var(--primaryColorText);
}

.codeIconHome {
    border: 1px solid var(--primaryColorText);
    position: absolute;
    top: 15px;
    right: 15px;
    width: 45px;
    height: 45px;
}

.backIconSize svg {
    width: 20px;
    height: 20px;
}

.codeIconHome svg {
    width: 30px;
    height: 30px;
}

.leaveIconHome svg {
    width: 23px;
    height: 23px;
}

.qrcodeModalBody img {
    width: 100%;
    height: 100%;
    padding: 1.5rem;
}

.qrcodeModalBody {
    min-height: 300px;
    margin: auto;
    text-align: center;
    display: flex;
    flex-direction: column-reverse;
}

.editUserModal,
.resetUserModal {
    text-align: left;
    display: flex;
    padding: 2rem;
    flex-direction: column-reverse;
    border-radius: 10px;
}

.resetUserModal .form-label {
    color: rgb(33, 37, 41);
}

.contactText1 {
    text-overflow: ellipsis;
    overflow: hidden;
    max-height: 3rem;
    line-height: 1.5rem;
    color: var(--baseColorText);
}

.form-floating>.form-control:focus~label,
.form-floating>.form-control:not(:placeholder-shown)~label,
.form-floating>.form-control-plaintext~label,
.form-floating>.form-select~label {
    opacity: 0.65;
    transform: scale(0.85) translateY(-0.7rem) translateX(0.15rem);
}

.fs-400 {
    font-weight: 500;
}

.my-node-enter {
    opacity: 0;
}

.my-node-enter-active {
    opacity: 1;
    transition: opacity 300ms;
}

.my-node-enter-done {
    opacity: 1;
    transition: opacity 300ms;
}

.my-node-exit {
    opacity: 1;
}

.my-node-exit-active {
    opacity: 0;
    transition: opacity 300ms;
}

.my-node-exit-done {
    opacity: 0;
}

/* Ranking */

.rankingCardChildren div {
    text-align: left;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    flex-wrap: nowrap;
}

.rankingText {
    text-overflow: ellipsis;
    overflow: hidden;
    max-height: 3rem;
    line-height: 1.5rem;
}

.rankingCardChildren {
    width: 100%;
    padding: 0.75rem 1rem;
}

.topNavCard {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;
}

.ranking-group .rankingCard:not(:last-of-type) {
    margin-bottom: 1rem;
}

.rankingCard {
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    border-radius: 10px;
}

/* Outros */

.fs-16 {
    font-size: 1rem !important;
}

.lineH {
    line-height: 1.2rem;
}