.app10 {

    .html {
        --frame: #fff;
    }

    .photoboothLogo {
        z-index: 1000;
        position: fixed;
        top: 5%;
        width: 100%;
        text-align: center;
    }

    .swiper {
        width: 100%;
        height: 100%;
    }

    .swiper-slide {
        overflow: hidden;
    }

    .photoButton {
        border-radius: 50% !important;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        text-align: center !important;
        flex-direction: column !important;
        position: relative;
        width: 75px;
        height: 75px;
    }

    .userHistoryButton {
        z-index: 10001;
        border-radius: 50% !important;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        text-align: center !important;
        flex-direction: column !important;
        position: relative;
        width: 45px;
        height: 45px;
        position: absolute;
        right: 15px;
        top: 15px;
    }

    .modalPresenter .cardPhotoButton {
        padding: 1rem 3rem;
        background-color: var(--primaryColor);
        color: var(--primaryColorText);
        border-radius: 25px;
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    }

    .modalPresenter .cardPhotoButton h5 {
        color: var(--primaryColorText) !important
    }

    .photoButton svg {
        width: 45%;
        height: 45%;
    }

    .photoButtonRefresh {
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 5px;
        position: absolute;
        right: 0;
        top: 0;
    }

    .cameraContainer img,
    .cameraContainer video,
    .cameraContainerPreview img,
    .cameraContainerPreview video {
        object-fit: cover;
        width: 100vw;
        height: 100vh;
    }

    .framePreview {
        background-image: var(--frame);
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    .cameraButtonContainer,
    .cameraButtonContainerPreview,
    .cameraButtonContainerRegister,
    .cameraButtonContainerEnd {
        left: 0;
        display: flex;
        width: 100%;
        justify-content: center;
        position: fixed;
        z-index: 1000;
    }

    .imageContainer {
        height: 350px;
    }

    .imageContainer img {
        object-fit: contain;
    }

    .photoboothTimer {
        left: 0;
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        position: fixed;
        z-index: 1000;
        bottom: 10%;
        font-size: 4rem;
        color: var(--primaryColor);
    }

    .photoboothTimer svg {
        font-size: 2rem;
    }

    .photoboothAnimationDiv:after {
        color: var(--primaryColorText);
    }

    .photoboothAnimation {
        pointer-events: none;
        position: absolute;
        overflow: hidden !important;
        z-index: 1000;
        left: calc(50% - 12.5vmin);
        top: calc(50% - 12.5vmin);
        font-family: "Poppins";
        color: var(--primaryColor);
        width: 25vmin;
        height: 25vmin;
        box-shadow: 0 0 0 0.75vmin, inset 1.75vmin 1.75vmin 5.5vmin rgba(0, 0, 0, 0.125), 1.75vmin 1.75vmin 5.5vmin rgba(0, 0, 0, 0.125);
        font-size: 15vmin;
        text-shadow: 1.75vmin 1.75vmin 5.5vmin rgba(0, 0, 0, 0.125);
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        font-weight: 700;
    }

    .photoboothAnimationDiv:before {
        content: "3";
        animation: 3s 1s forwards timer_countdown, 1s 0.875s 3 timer_beat;
        color: v
    }

    .webcamContainerPreview video {
        border-radius: 10px;
        border: 1px solid var(--bs-border-color);
        width: 100%;
        height: 100%;
    }

    .mySwiper {
        width: 100%;
        height: 100%;
    }

    .mySwiper .swiper {
        padding: 0px;
    }

    .mySwiper .swiper-slide {
        height: 100%;
    }

    .mySwiper .image-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        gap: 10px;
        width: 100%;
        height: 100%;
    }

    .mySwiper .image-grid img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .mediaItemsImg {
        border: 1px solid var(--bs-border-color);
    }
}