html {
  --font: 'Poppins', "Segoe UI", "Helvetica Neue", Arial, sans-serif;
  --transparency: rgba(0, 0, 0, 0.2);
  --background: rgba(255, 255, 255, 1);
  --primaryColor: rgba(255, 255, 255, 1);
  --primaryColorText: #4ba700;
  --baseColorText: rgba(0, 0, 0, 1);
  --boxShadow: rgba(0, 0, 0, .15);
  --paddingBox: 1.5rem;
  --h5: 1.25rem;
  --p: 1rem;
  --logoWidth: 300px;
  --baseColorText: rgba(0, 0, 0, 1);
  --incorrectAnswer: #cf000f;
  --correctAnswer: #009944;
  --invalidFeedback: #dc3545;
  --bs-border-color: #ced4da;
  --textSideColor: rgba(0, 0, 0, 1);
}

*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body {
  font-family: var(--font);
  overflow: hidden;
  height: 100%;
}

#root {
  height: 100%;
}

h5,
.h5 {
  font-size: var(--h5) !important;
}

small,
.small {
  font-size: 0.875em !important;
}

.form-check-input,
.btn,
.form-control,
label,
p,
.P {
  font-size: var(--p) !important;
}

.main {
  height: 100%;
  z-index: 1;
  background: var(--background) no-repeat center center / cover;
}

.btn {
  border: 0px;
  box-shadow: 0 0.125rem 0.25rem rgba(102, 90, 90, 0.075) !important;
}

.btn-outline {
  border: 1px solid var(--bs-border-color);
}

.card {
  background-color: var(--primaryColor);
  color: var(--primaryColorText);
  --bs-card-color: var(--primaryColorText);
  --bs-card-bg: var(--primaryColor);
  border: 1px solid var(--bs-border-color);
}

.routerContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.layout-01 {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-grow: 1;
  width: 100%;
  margin: auto;
  padding: 1.5rem;
}

.routerContainerPresenter {
  height: 100%;
  display: flex;
  overflow-y: auto;
}

.App-start {
  position: relative;
  color: #fff;
  margin: auto;
  padding: 1.5rem;
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App-start-box {
  font-family: 'Poppins', "Segoe UI", "Helvetica Neue", Arial, sans-serif !important;
  max-width: 580px;
  --bs-bg-opacity: 0.2;
  padding: 1.5rem;
  border-radius: 10px;
  width: 100%;
  background-color: rgba(0 0 0 /0.2);
  overflow: auto;
  box-shadow: 0 0.5rem 1rem var(--boxShadow);
}

.App-start-box .form-control {
  max-width: 250px !important;
  margin: auto;
}

.App-start .logo {
  max-width: 250px;
}

#tsparticles {
  z-index: 0;
  width: 100%;
  height: 100%;
  position: fixed;
}

.loaderContainer {
  height: 100%;
  z-index: 1;
}

.loadingScreenProtection {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100000;
}

/* TOKEN */
@media screen and (max-width: 500px) {

  .form-control.tokenButton:focus,
  .form-control.tokenButton {
    font-size: 15px !important;
    width: 38px !important;
    height: 38px !important;
  }
}

.form-control.tokenButton:focus,
.form-control.tokenButton {
  border-radius: 5px;
  color: #4ba700;
  font-size: 20px;
  font-weight: 700;
  height: 45px;
  margin-left: 5px !important;
  margin-right: 5px !important;
  padding: .25rem;
  text-align: center;
  text-transform: uppercase;
  width: 45px;
}

.presenterSidebarStart {
  background-color: transparent;
  position: relative;
  z-index: 2;
}

.presenterSidebarParent {
  box-shadow: 0 0.5rem 1rem #00000026;
}

.presenterSidebar {
  position: relative;
  display: flex;
  flex-direction: column;
  color: var(--baseColorText);
  background-color: var(--transparencyPresenter);
  height: 100vh;
  z-index: 1;
  height: 100vh;
  width: 325px;
}

.presenterSidebarTop {
  flex: 0;
  padding: 2rem 1.5rem 2rem 1.5rem;
}

.presenterSidebarBottom {
  padding: 1.5rem 1.5rem 2rem 1.5rem;
  overflow: auto;
}

.presenterSidebarBottom div {
  background-color: #fff;
  background-color: var(--primaryColor);
  border-radius: 10px;
  padding: 1.5rem;
}

.presenterSidebarBottom div h5 {
  color: var(--primaryColorText);
}

.youtubeIframe {
  width: 100%;
  max-width: 500px;
  height: 280px;
}

.sidebar {
  min-width: 300px;
  transition: margin-left 0.35s ease-in-out, left 0.35s ease-in-out, margin-right 0.35s ease-in-out, right 0.35s ease-in-out;
  direction: ltr;
  background-color: var(--primaryColor);
  position: absolute;
  width: 100%;
  z-index: 100;
  color: var(--primaryColorText);
}

.sidebar.collapsed {
  display: none;
}

.sidebar-content {
  height: 100vh;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  right: 0;
}

.sidebar-content {
  transition: margin-left 0.35s ease-in-out, left 0.35s ease-in-out, margin-right 0.35s ease-in-out, right 0.35s ease-in-out;
}

.sidebar .list-group-item:hover .medalRankingToggle {
  background-color: var(--primaryColor);
  color: var(--primaryColorText);
}

.sidebar .list-group-item:hover {
  background-color: var(--primaryColorText);
  color: var(--primaryColor);
}

.sidebar .list-group-item {
  margin-bottom: 0rem;
  border-color: var(--primaryColorText);
  color: var(--primaryColorText);
  background-color: transparent;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-left: 1rem;
  cursor: default;
}

.sidebar .list-group-item div {
  width: 100% !important;
  text-overflow: ellipsis;
  overflow: hidden;
}

.horizontally-centered {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.vertically-centered {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.opacity-0 {
  opacity: 0;
}

.opacity-75 {
  opacity: 0.50;
}

.flex-0 {
  flex: 0 0 !important;
}

.flex-1 {
  flex: 1 1 !important;
}

/* Login */
.loginLoadingContainer {
  height: 100%;
  background: var(--loadingBackground) no-repeat center center / cover;
}

.loginLoadingContainer .logo {
  width: var(--loadingLogoWidth);
}

.loginLoadingContainer div {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.navbar {
  top: 0;
  left: 0;
  z-index: 2;
  height: 65px;
  padding-top: 0px;
  padding-bottom: 0px;
  background-color: var(--primaryColor);
  color: var(--primaryColorText);
  box-shadow: 0 0.5rem 0.5rem #00000026;
}

.navbar button:active,
.navbar button:hover,
.navbar button:focus {
  background-color: var(--primaryColorText) !important;
  color: var(--primaryColor) !important;
  border: 1px solid var(--primaryColor) !important;
}

.navbar button:active svg,
.navbar button:hover svg,
.navbar button:focus svg {
  color: var(--primaryColor) !important;
}


.navbar button {
  background-color: transparent !important;
  color: var(--primaryColorText) !important;
  border: 1px solid var(--primaryColorText) !important;
}

.navbar {
  transition: background-color 0.5s ease;
}

/* Colors */
.baseColorText {
  color: var(--baseColorText) !important;
}

.primaryColor {
  background-color: var(--primaryColor) !important;
  color: var(--primaryColorText) !important;
}

.baseBorderColor {
  border: 1px solid var(--bs-border-color);
}

.primaryColorT {
  color: var(--primaryColor) !important;
}

.primaryColorText,
.primaryColor svg {
  color: var(--primaryColorText) !important;
}

.primaryColor-outline {
  background-color: transparent !important;
  border-color: 1px solid var(--primaryColor) !important;
  color: var(--primaryColor) !important;
}

.dataElementsContainer {
  border: 1px solid var(--bs-border-color);
  border-radius: 10px;
  padding: 1rem;
  text-align: left;
}

.modalDataFields span,
.dataElementsContainer span {
  opacity: 0.75;
}

.form-check-input:checked {
  background-image: url('../../assets/img/checkIcon.svg') !important;
  background-color: #fff !important;
  border: 1px solid var(--bs-border-color) !important;
}

.presenter-box .nav-item button.active {
  color: var(--primaryColorText) !important;
  background-color: var(--primaryColor) !important;
}

.btn-group .presenter-box .nav-item button {
  padding-left: 2rem;
  padding-right: 2rem;
  color: var(--baseColorText) !important;
  border: 0px;
}

@media (min-width:600px) {

  .navbar .dropdown .btn,
  .navbar .btn {
    --bs-btn-padding-y: 0.5rem;
    --bs-btn-padding-x: 1rem;
    --bs-btn-font-size: 1.25rem;
    --bs-btn-border-radius: 0.5rem;
  }
}

.quizzButtonImage.disabledImg,
.primaryColor .btn:disabled,
.primaryColor .btn:active,
.primaryColor .btn:hover {
  background-image: linear-gradient(rgb(255 255 255/15%) 0 0) !important;
}

.presenter-box .nav-tabs .nav-link {
  color: var(--baseColorText) !important;
  border: 0px !important;
  outline: 0px !important;
}

.presenterTab .btn {
  border: 1px solid #ced4da;
  color: #212529;
  background-color: #fff;
}

.presenterTab .btn:hover,
.presenterTab .btn:focus,
.presenterTab .btn:active,
.presenterTab .btn.active {
  border: 1px solid #ced4da;
  background-color: var(--primaryColor) !important;
  color: var(--primaryColorText) !important;
}

.ps__rail-y {
  left: unset !important;
  right: 0 !important;
}

.presenter-box .nav-tabs {
  border-width: 2px;
  border-color: var(--primaryColor) !important;
}

.rankingButtonGroup {
  overflow: auto;
  white-space: nowrap;
}

.rankingButtonGroup .questionsButtons {
  margin: 0rem 0.25rem 1rem 0.25rem;
  background-color: var(--primaryColor);
  color: var(--primaryColorText);
}

.rankingButtonGroup.active {
  color: rgb(255 255 255 / 1);
  background-color: rgb(255 255 255 / 0.2);
}

.rankingButtonGroup button {
  color: rgb(255 255 255 / 1);
  border-radius: 50%;
  width: 35px;
  height: 35px;
  padding: 0rem;
}

.medalRankingPadrao {
  width: 2.5rem;
  height: 2.5rem;
  display: grid;
  margin: auto;
  align-content: center;
  border-radius: 50%;
  background-color: var(--primaryColor);
  color: var(--primaryColorText) !important;
}

.medalRankingToggle {
  width: 2.5rem;
  height: 2.5rem;
  min-width: 2.5rem;
  min-height: 2.5rem;
  display: grid;
  margin: 0rem 0.5rem 0rem 0rem;
  align-content: center;
  border-radius: 50%;
  border: 1px solid var(--bs-border-color);

}

.presenteController {
  position: absolute;
  top: 50%;
  right: 0px;
}

.dropdown-item {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: var(--primaryColor) !important;
  color: var(--primaryColorText) !important;
}

.countdownButton {
  width: auto !important;
  position: absolute;
  padding: 0px !important;
  display: grid;
  align-items: center;
  min-width: 60px;
  min-height: 60px;
  border-radius: 50%;
  font-size: 20px;
  top: -15px;
  right: -15px;
}

.box {
  position: relative;
  color: var(--baseColorText);
  background-color: var(--transparency);
  box-shadow: 0 .3rem 1rem var(--boxShadow);
  padding: var(--paddingBox);
  border-radius: 10px;
  word-wrap: break-word;
  text-align: center;
  margin-top: 65px;
  width: 100%;
}

.box-full {
  width: 100%;
  position: relative;
  color: var(--baseColorText);
  background-color: var(--transparency);
  box-shadow: 0 .3rem 1rem var(--boxShadow);
  padding: var(--paddingBox);
  border-radius: 10px;
  word-wrap: break-word;
  text-align: center;
}

.footer {
  padding-top: 1rem;
  margin-top: auto;
}

.presenter-box,
.default-box,
.larger-box {
  color: var(--baseColorText) !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-grow: 1;
  width: 100%;
  margin: auto;
  padding: 1.5rem
}

.presenter-box {
  margin: 1.5rem auto auto auto;
}

.presenter-box .box {
  padding: 1.5rem;
  background-color: var(--transparencyPresenter) !important;
  box-shadow: 0 .3rem 1rem #00000026;
}

.larger-box {
  max-width: 950px;
}

.default-box {
  max-width: 650px;
}

.resultImage {
  width: 100%;
  height: auto;
}

.navbar div:first-of-type {
  flex: 1;
}

.navbar .logo {
  max-width: 150px;
  padding: 0.5rem 1rem 0.5rem 0rem;
  max-height: 65px;
}

.App-logo {
  width: 100%;
  padding: 1rem 1rem 1.5rem 1rem;
  object-fit: contain;
  max-width: var(--logoWidth);
}

.App-logo-flex {
  align-items: flex-end;
  justify-content: center;
  display: flex;
  color: #fff;
  padding-top: 1.5rem;
  padding-bottom: 0.5rem;
}

.App-logo-flex p {
  font-size: 13px !important;
  margin-bottom: 0.25rem;
  margin-right: -0.5rem;
}

.App-logo-flex img {
  width: 145px;
}

.logoBox {
  z-index: 1;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;
}

.loaderScreen {
  display: flex;
  justify-content: center;
}

.logoBox .logoBoxChildMiddle {
  text-align: center;
  flex: 1 1;
}

.logoBox .logoBoxHideButton {
  pointer-events: none;
  opacity: 0;
}

.logoBox .backButton {
  cursor: pointer;
  border-radius: 50%;
  background-color: var(--primaryColor);
  color: var(--primaryColor);
  width: 2.25rem;
  height: 2.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
}


.logoBox .backButton svg {
  color: var(--primaryColorText);
}

.mediaUpload img {
  object-fit: contain;
  max-height: 300px;
  height: auto;
  border-radius: 5px;
}

.mediaUpload iframe {
  border: 1px solid var(--bs-border-color);
  border-radius: 5px;
}

.mediaUpload iframe {
  width: 100%;
  max-height: 200px;
  height: 400px;
}

.larger-box .img-thumbnail {
  background-color: transparent !important;
}

.quizzButtonImage {
  transition: transform 0.5s ease !important;
  cursor: pointer;
  max-width: 100%;
  height: auto;
}

.backContainer {
  position: absolute;
  background-color: var(--primaryColor);
  color: var(--primaryColorText);
  border-radius: 50%;
  height: 3rem;
  width: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.quizzButtonContainer:last-child {
  margin-bottom: 1rem;
}

.quizzButton {
  padding: 1rem 0.5rem 1rem 0.5rem !important;
  transition: transform 0.3s ease !important;
  width: 100%;
  margin-top: 1rem;
  min-height: 75px;
  border: 0px !important;
}

.badge {
  font-weight: 500 !important;
}

.badgeSidebar {
  background-color: var(--primaryColor);
  color: var(--primaryColorText);
}

.endQuizzScoreIcon {
  background-color: var(--primaryColor);
  color: var(--primaryColorText);
  width: 110px;
  height: 110px;
  border-radius: 50%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  position: relative;
}

.endQuizzScoreIcon svg {
  position: absolute;
  top: 8px;
}

.was-validated .form-check-input:invalid~.form-check-label,
.form-check-input.is-invalid~.form-check-label {
  color: var(--baseColorText);
}

.correctAnswer {
  background-color: var(--correctAnswer) !important;
}

.incorrectAnswer {
  background-color: var(--incorrectAnswer) !important;
}

.noAnswer {
  border: 1px solid var(--bs-border-color);
}


.imageQuestion {
  max-width: 500px;
  height: auto;
  width: 100%;
}

.pieSize {
  height: 350px !important;
  width: 100% !important;
  margin: auto;
}

.pieSizeSmall {
  height: 200px !important;
  margin: auto;
  width: 100% !important;
}

.chartCard {
  margin-top: 1rem !important;
  padding: 1rem;
  border-radius: 10px;
  border: 1px solid var(--bs-border-color);
}

.barSize {
  width: 100% !important;
  margin: auto;
  height: 400px !important;
  max-width: 1000px !important;
}

.countdownBar {
  position: fixed;
  height: 20px;
  background-color: #000;
}

@media screen and (min-width: 720px) {
  .mediaUpload iframe {
    min-height: 400px;
  }

  .sidebar {
    position: relative;
    max-width: 300px;
  }

  .sidebar.collapsed {
    margin-right: -300px;
    display: block;
  }

  .countdownButton {
    min-width: 90px;
    min-height: 90px;
    top: -30px;
    right: -30px;
    font-size: 30px;
    border-width: 3px !important;
  }
}

@media screen and (min-width: 500px) {
  .countdownButton {
    min-width: 90px;
    min-height: 90px;
    top: -30px;
    right: -30px;
    font-size: 30px;
    border-width: 3px !important;
  }
}

.fade-out-image {
  animation: fadeOut 1s;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.fade-in-image {
  animation: fadeIn 1s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.countdown {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  margin: auto auto 1rem auto;
  background-color: #fff;
  left: calc(50% - 25px);
  align-items: center;
  display: flex;
  font-size: 20px;
  justify-content: center;
  background-color: var(--primaryColor);
  color: var(--primaryColorText);
}

.settings {
  display: none;
  direction: ltr;
  display: inline;
  font-family: 'Poppins', "Segoe UI", "Helvetica Neue", Arial, sans-serif !important;
  font-size: 0.825rem !important;
}

.settings-toggle-option {
  background: #edf2f5;
  border-radius: 0.2rem;
  color: #000;
  display: block;
  font-size: .875rem;
  font-weight: 500;
  color: #fff;
  height: 121px;
  padding: 0.5rem;
  text-align: center;
  text-transform: uppercase;
  writing-mode: vertical-rl;
}

.settings .settings-toggle {
  background: rgba(255, 255, 255);
  position: fixed;
  z-index: 101;
  top: calc(50% - 54px);
  bottom: 0px;
  right: 0px;
  width: 54px;
  height: 135px;
  padding: 0.5rem;
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
  box-shadow: -5px 0px 10px 0px rgba(0, 0, 0, 0.2);
  transition: all 0.1s ease-in-out;
  cursor: pointer;
}

.settings-panel {
  box-shadow: 0 .5rem 1rem var(--boxShadow);
  border-left: 0 solid transparent;
  display: block;
  height: 100%;
  position: fixed;
  width: 300px;
  z-index: 101;
  top: 0;
  bottom: 0;
  right: -320px;
  transition: right 0.2s ease-in-out;
}

.settings.open .settings-panel:before {
  content: "";
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  pointer-events: none;
}

.colorBlock {
  overflow: hidden;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--bs-border-color);
  width: 100%;
  height: 200px;
  border-radius: 10px;
}

.colorBlock>div {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}

.checkboxFontSize {
  font-size: 95%;
}

.settings.open .settings-panel {
  right: 0;
}

.settings .settings-content {
  height: 100%;
  overflow: auto;
  position: relative;
  display: flex;
  flex-direction: column;
}

.text-overflow {
  text-overflow: ellipsis;
  overflow: hidden;
}

.cardMessage {
  border: 1px solid var(--bs-border-color);
  position: relative;
  padding: 1rem 1rem 0.25rem 1rem;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.25s;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  word-wrap: break-word;
  text-overflow: ellipsis;
}

.cardMessage p {
  line-height: 20px;
}

.img-thumbnail {
  max-height: 250px;
}

.cardAplicativos {
  background-size: cover;
  background-position: center;
  border: 1px solid var(--bs-border-color);
  display: flex;
  position: relative;
  padding: 1rem 1rem 1rem 1rem;
  height: 150px;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.25s;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.cardType {
  background-color: var(--primaryColor);
  position: absolute;
  top: 0px;
  right: 0;
  margin-bottom: 0rem;
  padding: 0.15rem 0.5rem;
  border-radius: 5px;
  font-size: 85%;
  border-bottom-right-radius: 0px;
  border-top-left-radius: 0px;
}

.cardTypeDate {
  position: absolute;
  top: 3px;
  left: 0;
  margin-bottom: 0rem;
  padding: 0.15rem 0.5rem;
  border-radius: 5px;
  font-size: 85%;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 0px;
}

.cardTypeSelected {
  background-color: var(--primaryColor);
  color: var(--primaryColorText);
  position: absolute;
  top: 0;
  left: 0;
  margin-bottom: 0rem;
  padding: 0.15rem 0.5rem;
  border-radius: 5px;
  font-size: 85%;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 0px;
}

.cardType svg {
  color: var(--primaryColorText);
}

.cardContainer {
  position: relative;
  border-radius: 10px;
  transition: 0.25s;
}

.disableCardContainer {
  opacity: 0.5;
  pointer-events: none;
}

.cardHeader {
  width: 100%;
  background-color: var(--primaryColor);
  right: 0;
  bottom: 0;
  position: absolute;
  z-index: 2;
  width: 100%;
  background-color: var(--primaryColor);
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom: 1px solid var(--bs-border-color);
  border-right: 1px solid var(--bs-border-color);
  border-left: 1px solid var(--bs-border-color);
}

.cardHeader p {
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
  color: var(--primaryColorText);
}

.cardHistoryModal ul {
  padding: 0px;

}

.cardHistoryModal ul li {
  padding: 0.25rem 1rem 0.25rem 1rem;
  border-radius: 10px;
}

.cardRanking {
  display: flex;
  position: relative;
  border: 1px solid var(--bs-border-color);
  border-radius: 0.375rem;
  overflow: hidden;
  transition: 0.25s;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.cardRanking ul {
  width: 100%;
  padding: 0rem;
  margin: 0rem;
  max-height: 430px;
  overflow: auto;
}

.cardRankingHeader {
  background-color: var(--primaryColor);
  color: var(--primaryColorText);
  padding: 0.5rem;
  width: 100%;
}

.cardRanking li {
  padding: 0.5rem 1rem;
  border-bottom: 1px solid var(--bs-border-color);
  margin-bottom: 0rem !important;
}

.cardRanking li:last-of-type {
  border-bottom: 0px !important;
  margin-bottom: 0rem !important;
}

.cardRanking .textLabelRanking {
  font-size: 95% !important;
}

.cardRanking .contrastPlayer .medalRankingToggle {
  background-color: var(--primaryColor);
  color: var(--primaryColorText);
  border: 0px;
}

.showScoreSelection {
  align-items: center;
  justify-content: space-between;
  background-color: var(--primaryColor);
  display: flex;
  color: var(--primaryColorText);
  border-radius: 10px;
}

.showScoreSelection div {
  align-items: center;
  justify-content: space-between;
  display: flex;
}

.showScoreSelection div hr {
  opacity: 0.5;
  width: 1px;
  height: 25px;
  background-color: var(--primaryColorText);
  margin: 0;
}

.showScoreSelection p {
  padding: 0.25rem 0.75rem;
  margin-bottom: 0rem;
}

.pageTitle {
  height: 45px;
  width: 100%;
  margin-left: 1rem;
  align-items: center;
  font-size: 1.3rem;
  justify-content: center;
  display: flex;
  border-radius: 30px;
  background: var(--primaryColorText);
  color: var(--primaryColor);
}

.iconsHover:hover {
  transform: scale(1.3);
  -ms-transform: scale(1.3);
  -webkit-transform: scale(1.3);
  cursor: pointer;
  color: var(--primaryColor);
}

.iconsHover {
  transition: transform 0.3s ease;
}

.iconSize {
  cursor: pointer;
  flex-shrink: 0;
  min-width: 0;
  min-height: 0;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  display: flex;
}

.starIcon {
  position: absolute;
  top: 5px;
  right: 5px;
}

.rankingTime {
  position: absolute;
  top: 8px;
  right: 15px;
  font-size: 12px;
}

.presenterButtonSize {
  width: 45px;
  height: 45px;
  background: var(--primaryColor);
  color: var(--primaryColorText);
}

.qrcode-feedback {
  margin-top: 2rem;
  border-radius: 10px;
  height: 50px;
  width: 100%;
}

.noClickButton {
  background-color: var(--primaryColor) !important;
  color: var(--primaryColorText) !important;
  cursor: default;
  pointer-events: none;
}

.qrReaderContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.qrReaderContainer .qrReaderBox {
  width: 400px;
}

.qrcode-frame {
  border-radius: 10px;
  background-color: var(--transparency);
}

.modalPresenterColumn {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.modalPresenter {
  width: 100%;
  overflow: auto;
  position: relative;
}

.modalPresenter .modalQRCodeShare {
  position: absolute;
  display: flex;
  z-index: 1000;
}

.modalPresenter .modalQRCodeShare .qrcodeContainer {
  width: 150px;
  height: 150px;
}

.modalPresenter .modalQRCodeShare p {
  color: var(--primaryColorText) !important;
}

.modalPresenter .modalQRCodeShare div {
  background-color: var(--primaryColor);
  color: var(--primaryColorText);
  text-align: center;
  padding: 1rem;
  border-radius: 2px;
  margin: 0.5rem;
  width: 180px;
}

.modalPresenter .showAuthorBox {
  max-width: 750px;
  margin: auto;
}

.modalPresenter .modalPrizeDrawQRCode .qrcodeContainer {
  width: 400px;
  height: 400px;
}

.modalPresenter .visitorContainer {
  position: absolute;
  top: 15px;
  left: 15px;
}

.wheel-wrapper {
  overflow: hidden;
  position: relative;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.wheel-wrapper-panel {
  overflow: hidden;
  width: 100%;
  margin-top: 1.5rem;
  position: relative;
}

.dropdownMenuPresenter {
  min-width: 300px;
}

.qrcodeContainer {
  width: 225px;
  height: 225px;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-17 {
  font-size: 17px !important;
}

.settings-quiz .settings-content {
  background: var(--primaryColor);
  color: var(--primaryColorText);
}

.settings .settings-content {
  background: rgba(255, 255, 255);
}

.settings-quiz hr {
  border-color: var(--primaryColorText);
}

.settings .settings-title {
  padding: 1.35rem 1.5rem;
  font-size: 0.875rem;
  background: rgba(255, 255, 255);
  border-bottom: 1px solid rgb(73, 80, 87) !important;
}

.picker {
  position: relative;
}

.settings-title h4,
.settings-title .h4,
.settings,
.settings h5,
.settings h6,
.settings p {
  color: rgb(73, 80, 87) !important;
}

.btn-settings {
  background-color: white;
}

.swatch {
  width: 28px;
  height: 28px;
  border-radius: 0.2rem;
  border: 1px solid var(--bs-border-color);
  cursor: pointer;
}

.popover {
  position: absolute;
  top: calc(100% + 2px);
  left: 0;
  border-radius: 9px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.settings-body {
  padding: 1rem 1.5rem;
  flex: 1;
}

.settings-footer {
  padding: 1rem 1.5rem;
}

.settings-button-label {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

.disable-selection {
  pointer-events: none;
}


.settings-button-label+.settings-button {
  background: rgba(75, 167, 0);
  color: rgba(255, 255, 255);
  border-radius: 5px;
  padding: 0.35rem 1rem;
  font-size: 0.825rem;
  border-radius: 0.3rem;
  cursor: pointer;
}

.settings-button-label:checked+.settings-button:after {
  opacity: 1;
}

.form-control {
  font-size: .95rem;
}

.fs-07 {
  font-size: 0.7rem !important;
}

.pointer {
  cursor: pointer;
}

.tablePresenter {
  width: 100%;
  position: relative;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 10px;
  overflow: auto;
}

.tablePresenter td {
  vertical-align: middle;
}

.modal-content .tablePresenter {
  overflow: unset;
}

.modal-content .tablePresenter th {
  vertical-align: middle;
  padding: 0.5rem 1rem;
  background-color: var(--primaryColor) !important;
  color: var(--primaryColorText) !important;
}

.modal-content .tablePresenter th:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.modal-content .tablePresenter th:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.tablePresenter .page-item:not(.disabled)>.page-link {
  color: #212529 !important;
}

.presenter-box .form-check-label,
.presenter-box .modalPresenter h5,
.presenter-box .modalPresenter p,
.presenter-box .tablePresenter tr,
.presenter-box .tablePresenter th,
.presenter-box .tablePresenter span {
  color: var(--baseColorText) !important;
}

.modalPresenter .form-check-label,
.modalPresenter label,
.modalPresenter h5,
.modalPresenter p,
.tablePresenter span,
.tablePresenter tr,
.tablePresenter th {
  color: var(--textSideColor) !important;
}

.invalid-feedback {
  color: var(--invalidFeedback);
}

.page-link.disabled,
.disabled>.page-link {
  opacity: 0.75 !important;
}

.tablePresenter tr:last-of-type {
  border-color: transparent;
}

.cursor-pointer {
  cursor: pointer;
}

.modalPresenter {
  display: flex;
  height: 100%;
}

/* ID: 03 */

.modalPresenter h5 .messagesSlider .messagesContainer {
  width: 100%;
  overflow: hidden;
  word-wrap: break-word;
  text-overflow: ellipsis;
  margin-bottom: 1rem;
}

.modalPresenter .image-side {
  background-size: cover;
  background-position: center;
}

.modalPresenter .text-side {
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
}

.modalPresenter .firstTitle {
  width: 100%;
  text-align: center;
}

.apresentationTitle {
  margin-top: 5rem;
}

.messageDisplayHistory {
  width: 100%;
}

.flex-grow {
  flex-grow: 1;
}


.swiper {
  width: 100%;
  height: 100%;
}

.messagesSlider .swiper-slide {
  height: 100%;
  width: 100%;
  text-align: center;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
}

.messagesSlider .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.messagesSlider .autoplay-progress {
  width: unset;
  position: absolute;
  right: 0px;
  bottom: 0px;
  z-index: 10;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  background-color: var(--primaryColor);
  color: var(--primaryColorText);
  border-radius: 50%;
}

.messagesSlider .autoplay-progress svg {
  --progress: 0;
  position: absolute;
  left: 0;
  top: 0px;
  z-index: 10;
  width: 100%;
  height: 100%;
  fill: none;
  transform: rotate(-90deg);
}

.QAnswerFooterNotCheck {
  color: var(--primaryColor) !important;
  border: 2px solid var(--primaryColor) !important;
}

.QAnswerFooterCheck {
  background-color: var(--primaryColor) !important;
  border: 2px solid var(--primaryColor) !important;
  color: var(--primaryColorText) !important;
}

.QAnswerFooter {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
}

.messagesSlider {
  display: flex;
}

.messagesSlider p {
  margin: auto;
  color: var(--baseColorText);
  max-width: 1200px;
  overflow-wrap: break-word;
  margin-bottom: 0px;
  padding: 0px 4rem 0px 4rem;
}

.swiper-button-prev:after,
.swiper-button-next:after {
  color: var(--primaryColor) !important;
}

.swiper-pagination-bullet {
  background-color: var(--primaryColor) !important;
}

/* ID 05 */
.wordcloud-container {
  width: 100%;
  height: 100%;
}

/* ID 06 */

.curtain {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 2rem;
  z-index: 1;
}

.curtain .userInfo {
  position: relative;
  border-radius: 10px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.notyf__dismiss {
  display: flex;
}

.notyf__dismiss-btn {
  opacity: 1 !important;
  margin: auto auto auto 0px;
  width: calc(100% - 5px) !important;
  height: calc(100% - 10px) !important;
}

.notyf__dismiss-btn:after,
.notyf__dismiss-btn:before {
  left: calc(50% - 4px) !important;
}


/* ID:06 */

.prizePresenterContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
}

.presentWinnerBox {
  text-align: center;
  padding: 0.5rem 1.5rem;
  margin: 1rem 0.5rem;
  border-radius: 25px;
  background-color: var(--primaryColor);
  color: var(--primaryColorText);
}

.presentWinnerBox p {
  color: var(--primaryColorText) !important;
}

.sorteioResultadosListItem {
  width: 100%;
  background-color: var(--primaryColor);
  color: var(--primaryColorText);
}

/* ID:07 */

.rouletteConfigsCard {
  height: 650px;
}

.wheelButton {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  background-color: var(--primaryColor);
  color: var(--primaryColorText);
  padding: 1rem 3rem;
  border-radius: 25px;
  cursor: pointer;
  z-index: 10;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* ID 08 */

.stepComponent-box {
  max-width: 600px;
  width: 100%;
}

.stepComponent {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  padding: 1rem 0rem 0rem 0rem;
  margin: 0rem;
}

.stepComponent li {
  flex-direction: column;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  pointer-events: none;
}

.stepComponent li .stepSizeButton {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  position: relative;
  border: 1px solid var(--bs-border-color);
}

.stepComponent .label {
  margin-top: 1rem;
}

.modal-title {
  font-size: 1.2rem;
}

.audio-recorder {
  display: none !important;
}

.avatar-box {
  display: flex;
  justify-content: center;
}

.avatar-box svg {
  width: 80px;
  height: 80px;
  margin: 2rem 1rem;
  color: var(--primaryColor) !important;
  cursor: pointer;
}

/* APP 09 */
.GameProviderNetworking .default-box {
  box-shadow: 0 .5rem 1rem var(--boxShadow);
}

/* OTHERS */

.accordion-button:not(.collapsed) {
  background-color: var(--primaryColor) !important;
  color: var(--primaryColorText) !important;
}

.accordion-button:not(.collapsed)::after {
  color: var(--primaryColorText) !important;
}

.modal {
  --bs-modal-padding: 1.5rem !important;
  padding: 0rem !important
}

body.modal-open {
  padding-left: 0 !important;
}

.modal-dialog .list-group-item {
  border-color: var(--baseColorText);
  background-color: rgba(0, 0, 0, 0);
}

.list-group-item {
  border-color: var(--bs-border-color) !important;
}

.list-group-item.appList:hover,
.list-group-item.appList {
  background-color: var(--primaryColor);
  color: var(--primaryColorText);
}

.list-group-item.appList:hover p,
.list-group-item.appList p {
  color: var(--primaryColorText);
}

.list-group-item.appList:hover .badge,
.list-group-item.appList .badge {
  background-color: var(--primaryColorText);
  color: var(--primaryColor);
}

.list-group-item.appList:active,
.list-group-item.appList:hover,
.list-group-item.appList:focus {
  background-color: var(--primaryColorText);
  color: var(--primaryColor);
  border: 1px solid var(--primaryColor);
}

.list-group-item.appList:active p,
.list-group-item.appList:hover p,
.list-group-item.appList:focus p {
  color: var(--primaryColor);
}

.list-group-item.appList:active .badge,
.list-group-item.appList:hover .badge,
.list-group-item.appList:focus .badge {
  color: var(--primaryColor);
}

.presenterSidebarBottom .list-group-item+.list-group-item {
  border-top-width: 1px !important;

}

.list-group-item:last-child {
  margin-bottom: 0rem !important;
}

.list-group-item.appList {
  background-color: var(--primaryColor);
  color: var(--primaryColorText);
}

.list-group-item.appList p {
  color: var(--primaryColorText);
}

.list-group-item.appList .badge {
  background-color: var(--primaryColorText);
  color: var(--primaryColor);
}

.videoBackground {
  height: 100%;
  object-fit: cover;
  width: 100%;
  position: fixed;
}

/* Quizz start animation */
.quizzAnimationDiv:after {
  color: var(--primaryColorText);
}

.quizzAnimation {
  overflow: hidden !important;
  z-index: 10;
  font-family: "Poppins";
  background-color: var(--primaryColorText);
  color: var(--primaryColor);
  width: 75vmin;
  height: 75vmin;
  box-shadow: 0 0 0 1.75vmin, inset 3.75vmin 3.75vmin 7.5vmin rgba(0, 0, 0, 0.125), 3.75vmin 3.75vmin 7.5vmin rgba(0, 0, 0, 0.125);
  font-size: 45vmin;
  text-shadow: 3.75vmin 3.75vmin 7.5vmin rgba(0, 0, 0, 0.125);
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-weight: 700;
}

@media (min-width: 600px) {
  .quizzAnimation {
    max-width: 350px;
    max-height: 350px;
    width: 40vmin;
    height: 40vmin;
    box-shadow: 0 0 0 1.75vmin, inset 2.5vmin 2.5vmin 5vmin rgba(0, 0, 0, 0.125), 2.5vmin 2.5vmin 5vmin rgba(0, 0, 0, 0.125);
    font-size: 10rem;
    text-shadow: 2.5vmin 2.5vmin 5vmin rgba(0, 0, 0, 0.125);
  }

  .quizzAnimationbtn {
    font-size: 20vmin !important;
  }

}

.quizzAnimationbtn {
  color: var(--primaryColor) !important;
  font-size: 25vmin;
  cursor: pointer;
}

.quizzAnimationDiv:before {
  content: "3";
  animation: 3s 1s forwards timer_countdown, 1s 0.875s 3 timer_beat;
  color: var(--primaryColorText);
}


@keyframes notyf-fadeinup {
  0% {
    opacity: 0;
    transform: translateY(25%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes notyf-fadeinleft {
  0% {
    opacity: 0;
    transform: translateX(25%);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes notyf-fadeoutright {
  0% {
    opacity: 1;
    transform: translateX(0);
  }

  to {
    opacity: 0;
    transform: translateX(25%);
  }
}

@keyframes notyf-fadeoutdown {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  to {
    opacity: 0;
    transform: translateY(25%);
  }
}

@keyframes ripple {
  0% {
    transform: scale(0) translateY(-45%) translateX(13%);
  }

  to {
    transform: scale(1) translateY(-45%) translateX(13%);
  }
}

.notyf {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  color: #fff;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  pointer-events: none;
  box-sizing: border-box;
  padding: 20px;
}

.notyf__icon--error,
.notyf__icon--success {
  color: #fff;
  height: 21px;
  width: 21px;
  background: #fff;
  border-radius: 50%;
  display: block;
  margin: 0 auto;
  position: relative;
}

.notyf__icon--error:after,
.notyf__icon--error:before {
  content: "";
  background: currentColor;
  display: block;
  position: absolute;
  width: 3px;
  border-radius: 3px;
  left: 9px;
  height: 12px;
  top: 5px;
}

.notyf__icon--error:after {
  transform: rotate(-45deg);
}

.notyf__icon--error:before {
  transform: rotate(45deg);
}

.notyf__icon--success:after,
.notyf__icon--success:before {
  content: "";
  background: currentColor;
  display: block;
  position: absolute;
  width: 3px;
  border-radius: 3px;
}

.notyf__icon--success:after {
  height: 6px;
  transform: rotate(-45deg);
  top: 9px;
  left: 6px;
}

.notyf__icon--success:before {
  height: 11px;
  transform: rotate(45deg);
  top: 5px;
  left: 10px;
}

.notyf__toast {
  display: block;
  overflow: hidden;
  pointer-events: auto;
  animation: notyf-fadeinup 0.3s ease-in forwards;
  box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.25);
  position: relative;
  padding: 0 15px;
  border-radius: 2px;
  max-width: 300px;
  transform: translateY(25%);
  box-sizing: border-box;
  flex-shrink: 0;
}

.notyf__toast--disappear {
  transform: translateY(0);
  animation: notyf-fadeoutdown 0.3s forwards;
  animation-delay: 0.25s;
}

.notyf__toast--disappear .notyf__icon,
.notyf__toast--disappear .notyf__message {
  animation: notyf-fadeoutdown 0.3s forwards;
  opacity: 1;
  transform: translateY(0);
}

.notyf__toast--disappear .notyf__dismiss {
  animation: notyf-fadeoutright 0.3s forwards;
  opacity: 1;
  transform: translateX(0);
}

.notyf__toast--disappear .notyf__message {
  animation-delay: 0.05s;
}

.notyf__toast--upper {
  margin-bottom: 20px;
}

.notyf__toast--lower {
  margin-top: 20px;
}

.notyf__toast--dismissible .notyf__wrapper {
  padding-right: 30px;
}

.notyf__ripple {
  height: 400px;
  width: 400px;
  position: absolute;
  transform-origin: bottom right;
  right: 0;
  top: 0;
  border-radius: 50%;
  transform: scale(0) translateY(-51%) translateX(13%);
  z-index: 5;
  animation: ripple 0.4s ease-out forwards;
}

.notyf__wrapper {
  display: flex;
  align-items: center;
  padding-top: 17px;
  padding-bottom: 17px;
  padding-right: 15px;
  border-radius: 3px;
  position: relative;
  z-index: 10;
}

.notyf__icon {
  width: 22px;
  text-align: center;
  font-size: 1.3em;
  opacity: 0;
  animation: notyf-fadeinup 0.3s forwards;
  animation-delay: 0.3s;
  margin-right: 13px;
}

.notyf__dismiss {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 26px;
  margin-right: -15px;
  animation: notyf-fadeinleft 0.3s forwards;
  animation-delay: 0.35s;
  opacity: 0;
}

.notyf__dismiss-btn {
  background-color: rgba(0, 0, 0, 0.25);
  border: none;
  cursor: pointer;
  transition: opacity 0.2s ease, background-color 0.2s ease;
  outline: none;
  opacity: 0.35;
  height: 100%;
  width: 100%;
}

.notyf__dismiss-btn:after,
.notyf__dismiss-btn:before {
  content: "";
  background: #fff;
  height: 12px;
  width: 2px;
  border-radius: 3px;
  position: absolute;
  left: calc(50% - 1px);
  top: calc(50% - 5px);
}

.notyf__dismiss-btn:after {
  transform: rotate(-45deg);
}

.notyf__dismiss-btn:before {
  transform: rotate(45deg);
}

.notyf__dismiss-btn:hover {
  opacity: 0.7;
  background-color: rgba(0, 0, 0, 0.15);
}

.notyf__dismiss-btn:active {
  opacity: 0.8;
}

.notyf__message {
  font-size: 0.95rem;
  vertical-align: middle;
  position: relative;
  opacity: 0;
  animation: notyf-fadeinup 0.3s forwards;
  animation-delay: 0.25s;
  line-height: 1.5em;
}

@media only screen and (max-width: 480px) {
  .notyf {
    padding: 0;
  }

  .notyf__ripple {
    height: 600px;
    width: 600px;
    animation-duration: 0.5s;
  }

  .notyf__toast {
    max-width: none;
    border-radius: 0;
    box-shadow: 0 -2px 7px 0 rgba(0, 0, 0, 0.13);
    width: 100%;
  }

  .notyf__dismiss {
    width: 56px;
  }
}

.bg-transparent {
  background-color: transparent !important;
}

.buttonWithIcon {
  width: 50px;
}