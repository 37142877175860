.secondPlayerColorCard,
.firstPlayerColorCard {
    --bs-card-spacer-y: 1rem;
    --bs-card-spacer-x: 1rem;
    --bs-card-title-spacer-y: 0.5rem;
    --bs-card-border-radius: var(--bs-border-radius);
    --bs-card-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
    --bs-card-cap-padding-y: 0.5rem;
    --bs-card-cap-padding-x: 1rem;
    --bs-card-img-overlay-padding: 1rem;
    --bs-card-group-margin: 0.75rem;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    height: var(--bs-card-height);
    word-wrap: break-word;
    background-clip: border-box;
    border-radius: var(--bs-card-border-radius);
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}


@media only screen and (min-width: 576px) {
    .memoryGameBox .cardFlip {
        padding: var(--paddingCard) !important;
    }
}

.memoryGameBox .cardFlip {
    padding: 0.5rem;
}

/* Game Definition Page */
.selectPlayerImage {
    padding: 0.75em;
    width: 100%;
}

.showSelectPlayerCard,
.selectPlayerCard {
    flex: 0 1 !important;
}

.selectPlayerCard div {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: white;
}

.showSelectPlayerCard div {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    background-color: white;
}

/* Memory Game */
.memoryGameBox {
    padding: 1.5rem;
    text-align: center;
    margin: auto !important;
    justify-content: center !important;
    display: flex;
    flex-wrap: wrap;
}

.memoryCardPlayers {
    right: 0;
    width: 250px;
    position: absolute;
    overflow: hidden;
    border-radius: 0px;
    display: flex;
    justify-content: flex-end;
}

.memoryCardPlayers div:first-of-type {
    width: 100%;
    max-width: 250px;
}

.memoryCard {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
    transform-style: preserve-3d;
    transition: transform 0.6s;
}

.memoryCardContainer {
    perspective: 1000px;
}

.memoryCard.flipped {
    transform: rotateY(180deg);
}

.card-face {
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
}

.memoryCardBack {
    transform: rotateY(180deg);
}

.flip-card {
    background-color: transparent;
    width: 100%;
    height: 100%;
    perspective: 1000px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

/* Ensure flip-card-inner takes up 100% of the parent's dimensions */
.flip-card-inner {
    position: absolute;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
}

/* Define the flipped state */
.flip-card.flipped .flip-card-inner {
    transform: rotateY(180deg);
}

/* Front and back faces occupy 100% of the parent's dimensions */
.flip-card-front,
.flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
}

.flip-card-back {
    transform: rotateY(180deg);
}

/* Ensure images take up the full space of the container */
.flip-card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}